<template>
    <el-drawer
        title="设置专业版登录密码"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                
                <el-form-item prop="password" label="密码:">
					<span slot="label">密码: <small>8-40个字符，且必须包含至少1位字母、1位数字</small></span>
                    <el-input v-model="formData.password" placeholder="请输入密码" type="password" size="medium" maxlength="40" show-password auto-complete="new-password"></el-input>
                </el-form-item>
               
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>


const defaultFormData = {
    password:''
    
}
export default {
	props:{
	    userId:{
	        type:[String,Number],
	        default:''
	    }
	},
    data(){
		//验证密码
		const validatePassword = (rule, value, callback) => {
		     const _reg = /^(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 _]{8,40}$/
		     if (!_reg.test(value)) {
		         callback(new Error("登录密码必须包含8-40个字符，且必须包含至少1位字母、1位数字"));
		     } else {
		         callback();
		     }
		 }
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{...defaultFormData},
			id:'',	
            rules:{
                password:[
                    {required:true,message:'请输入密码'},
					{validator:validatePassword}
                ]
               
            }            
        }
    },
    created(){
       
    },
    mounted(){
       
    },
    methods:{
        close(){            
            this.$refs.formData.resetFields();
            this.drawerShow = false;
        },
        show(id){
			this.id = id;
            this.formData = {
                ...defaultFormData,           
            }
            this.drawerShow = true;
            this.$nextTick(() => {
                this.$refs.formData.clearValidate();
            })
        },
        
        //表单提交
        submitForm(){
			
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true
                    const _url = '/subUser/resetPass';
                    this.$request({
                        url: _url,
                        params: {
                            ...this.formData,
							userId:this.id,
																
                        },
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('onUpdate');
                                this.$message.success("操作成功");
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });    
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
    .input-with-button{
        // ::v-deep .el-input-group__append{
        //     background: transparent;
        //     color: $--color-theme;
        // }
        .el-button{
            padding: 0 10px;
        }
    }
    .el-checkbox-group{
        font-size: 14px;
        .fl{
            margin-right: 10px;
            min-width: 100px;
        }
        .description{
            overflow: hidden;
            line-height: normal;
            padding-top: 10px;
            color: $--color-text-regular;
            max-width: 400px;
        }
    }
    
</style>