<template>
    <el-drawer
        :title="formData.userId ? '编辑工作人员' : '添加工作人员'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                <template v-if="!formData.userId">
                    <el-form-item prop="telephone">
                        <span slot="label">手机号: <small>工作人员仅能使用手机号+短信验证码登录</small></span>
                        <el-input v-model="formData.telephone" placeholder="仅支持11位大陆手机号" size="medium"></el-input>
                    </el-form-item>
					<el-form-item prop="password" label="专业版登录密码:">
						<span slot="label">专业版登录密码: <small>8-40个字符，且必须包含至少1位字母、1位数字</small></span>
					    <el-input v-model="formData.password" placeholder="请输入专业版登录密码" type="password" size="medium" maxlength="40" show-password  auto-complete="new-password"></el-input>
					</el-form-item>
                    <el-form-item prop="smsCode" label="短信验证码">
                        <div class="input-with-button">
                            <el-input v-model="formData.smsCode" placeholder="请输入短信验证码" size="medium">
                                <el-button
                                    slot="append"
                                    native-type="button"
                                    type="text"
                                    @click="getCode()"
                                    :disabled="codeBtn.disabled"
                                    :loading="codeBtn.loading"
                                >{{codeBtn.btnText}}</el-button>
                            </el-input>
                        </div>
                    </el-form-item>
                </template>
                <el-form-item prop="idName" label="头衔:">
                    <el-input v-model="formData.idName" placeholder="比如:小编" size="medium" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item prop="roleIds" label="权限设置:">
                    <el-checkbox-group v-model="formData.roleIds" size="medium">
                        <div v-for="(item,index) in roleList" :key="index" class="clearfix">
                            <div class="fl">
                                <el-checkbox :label="item.id">
                                    {{item.roleName}}
                                </el-checkbox>
                            </div>    
                            <div class="description">{{item.description}}</div>
                        </div>    
                    </el-checkbox-group>
                </el-form-item>
				<el-form-item label="授权演出:">
                    <span slot="label">
                        授权演出:
                        <el-popover
                            placement="top"
                            title="说明"
                            width="360"
                            trigger="hover">
                            <div>
                                <div>涉及查看演出的权限具体包括：</div>
                                <div class="MT10">1、经营平台：【演出列表】【演出结款申请】</div>
                                <div class="MT10">2、专业版：【开始验票】【退票申请】【演出结款】</div>
                            </div>
                            <span slot="reference" >在有查看演出的权限前提下<i class="el-icon-question"></i></span>
                        </el-popover>
                    </span>
					<el-radio-group v-model="formData.activityLicensed" size="medium">
					    <el-radio :label="0">可以看到所有演出</el-radio>
					    <el-radio :label="1">只能看到以下演出</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="formData.activityLicensed == 1 ">				    
				    <el-input placeholder="输入演出ID检索演出" v-model="sActivityIds">
				        <el-button slot="append" icon="el-icon-search" @click="searchActivity()">添加演出</el-button>
				    </el-input>
				    <el-table class="MT10 sortable-table" :data="sActivityList" size="mini" border stripe v-loading="sActivityLoading" row-key="id">				       
				        <el-table-column prop="activityId" label="演出ID" width="90"></el-table-column>
				        <el-table-column prop="name" label="演出名称" show-overflow-tooltip></el-table-column>				        
				        <el-table-column label="操作" width="80">
				            <template slot-scope="scope">
				                <el-button type="text" @click="delActivity(scope.row.id)">删除</el-button>
				            </template>
				        </el-table-column>
				    </el-table>
				</el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import {fetchCode, verificationSwitch} from '@/assets/js/commonApi';

const defaultFormData = {
    idName:'',
	password:'',
    roleIds:[],
    smsCode:'',
    telephone:'',
	activityLicensed:0
}
export default {
    data(){
		//验证密码
		const validatePassword = (rule, value, callback) => {
		     const _reg = /^(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 _]{8,40}$/
		     if (!_reg.test(value)) {
		         callback(new Error("密码必须包含8-40个字符，且必须包含至少1位字母、1位数字"));
		     } else {
		         callback();
		     }
		 }
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{...defaultFormData},
            rules:{
                telephone:[
                    {required:true,message:'请输入手机号码'},
                    {validator: this.$utils.validatePhone}
                ],
				password:[
				    {required:true,message:'请输入专业版登录密码'},
					{validator:validatePassword}
				],
                smsCode:[{required:true,message:'请输入短信验证码'},],
                roleIds:[{required:true,message:'请设置权限'}]
            },
            codeBtn: {
                btnText: "获取验证码",
                disabled: false,
                loading: false
            },
            captcha: null,
            roleList:[],
            timer: null,
			
			sActivityIds: "",
			sActivityLoading: false,
			sActivityList: [],
			params:{
				searchType:1,
				sellType:0,
				progressType:0,
				pageNo:1,
				pageSize:100
			},
			showActivity:0
        }
    },
    created(){
        const that = this;
        this.$utils.loadScript(
            {
                id:'tcaptcha',
                url:'https://turing.captcha.qcloud.com/TCaptcha.js'
            },
            () =>{
                // eslint-disable-next-line no-undef
                this.captcha = new TencentCaptcha(
                    "2032912416", 
                    function(res){
                        if(res.ret === 0){
                            that.getCodeEvent( res );
                        }
                    }
                );
            }
        )
    },
    mounted(){
        this.getRole();
    },
    methods:{
        close(){
            if(this.timer){
                clearTimeout(this.timer);
                this.dtime(0);
            }
			this.sActivityList = []
            this.$refs.formData.resetFields();
            this.drawerShow = false;
        },
        show(data){
            this.formData = {
                ...defaultFormData,
                ...data,
                // telephone: data && data.telephone && !data.telephone.includes('tmp') ? data.telephone : '',
                roleIds: data && data.roles ? data.roles.map( v => v.roleId) : []
            }
            this.drawerShow = true;
            this.$nextTick(() => {
                this.$refs.formData.clearValidate();
            })
			
			if( data.userId ){
				
				this.getActivity()
			}
        },
        //获取短信验证码
        getCode() {
            this.$refs["formData"].validateField(["telephone"], async (err) => {
                if(!err){
                    const res = await verificationSwitch(); //获取验证码设置
                    const { state, msg, result } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "获取验证码设置失败");
                    } else {
                        if( result.suduAccount ){
                            this.captcha.show();
                        }else{
                            this.getCodeEvent();
                        }
                    }
                }
            });
        },
        getCodeEvent(_res){
            this.codeBtn.loading = true;
            fetchCode({
                biz:'reg_sub',
                phone: this.formData.telephone,
                randStr:_res && encodeURIComponent(_res.randstr) || '',
                ticket: _res && encodeURIComponent(_res.ticket) || '',
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "请求失败");
                    } else {
                        // this.formData.securityCode = res.data.result;
                        this.dtime(60)
                    }
                })
                .catch(() => {
                    this.$message.error('请求错误');
                }).finally( () => {
                    this.codeBtn.loading = false;
                });
        },
        dtime( time ){
            var that = this , _time = time;
            if( _time <= 0 ){
                this.codeBtn.btnText = '获取验证码';
                this.codeBtn.disabled = false;
            }else{
                this.codeBtn.btnText =  _time+'秒后重试' ;
                _time --;
                this.codeBtn.disabled = true;
                this.timer = setTimeout(function(){ that.dtime( _time );clearTimeout(this.timer); } , 1000);
            }
        },
		//搜索演出
		async searchActivity(callback) {
		    if (this.sActivityIds) {
		        this.sActivityLoading = true;
		
		        const res = await this.$request({
		            url: '/activity/list',
		            method: 'post',
		            data: {...this.params,searchValue:this.sActivityIds}
		        }).catch(() => {
		            return { data: { state: '0', msg: '请求错误' } };
		        }).finally(() => {
		            this.sActivityLoading = false;
		        });
		
		        const { state, msg, result } = res.data;
		
		        if (state != 1) {
		            return this.$message.error(msg || '请求失败');
		
		        } else {
		            if (result && result.result.length > 0) {
		
		                result.result.map(item => {
		                    if (!this.sActivityList.find(v => v.activityId == item.id)) {
		                        //this.sActivityList.push(item)
								
								result.result.map(item => {
								    this.sActivityList.push({
								        activityId: item.id,
										name: item.title,
										isNew:1,
										id:''
								    })
								})
								
								
		                    }
		                })
		                
		                callback && callback();
						this.sActivityIds = ''
		
		            } else {
		                this.$message.error(msg || '没有找到相关演出');
		            }
		        }
		    }
		},
		//删除演出  /subUser/removeLicensedActivity
		delActivity(id) {			
			this.sActivityList.map(item => {
				if( !item.isNew && id != ''){					
					this.$alert('是否确认删除该演出的查看权限？确认后立即生效！', {
					    confirmButtonText: '确定',
					    showCancelButton:true,
					    showClose:false,
					    dangerouslyUseHTMLString:true,
					    customClass:'mpop',
					}).then( () => {
					    this.$request({
					        url: '/subUser/removeLicensedActivity',
					        params: {id:id},
					        method: "post",
					    })
					        .then((res) => {
					            const { state,result } = res.data;
					            if (state == 1) {
					                this.sActivityList = this.sActivityList.filter(v => v.id != id);
									this.getActivity()
					            } else {
					                // this.$message.error(msg || "操作失败");
					            }
					        })
					        .catch(() => {
					            // this.$message.error("操作失败");
					        })
					        .finally(() => {
					            // this.btnLoading = false;
					        });
					}).catch(() => {});
					
				}
				
			})
			
			this.sActivityList.map(item => {
				if( item.isNew == 1 ){
					this.sActivityList = this.sActivityList.filter(v => v.id != id);
				}
			})
			
			
			
		},
        //获取角色权限
        getRole(){
            this.$request({
                url: '/role/list',
                params: this.formData,
                method: "post",
            })
                .then((res) => {
                    const { state,result } = res.data;
                    if (state == 1) {
                        this.roleList = result
                    } else {
                        // this.$message.error(msg || "操作失败");
                    }
                })
                .catch(() => {
                    // this.$message.error("操作失败");
                })
                .finally(() => {
                    // this.btnLoading = false;
                });   
        },
		getActivity(){
			this.sActivityList = []
			this.$request({
			    url: '/subUser/licensedActivityList',			  
				params: {userId:this.formData.userId,pageNo:1,pageSize:100},
			    method: "post",
			})
			    .then((res) => {
			        const { state,result } = res.data;
			        if (state == 1 && result && result.result.length > 0 ) {
						
						result.result.map(item => {
						    this.sActivityList.push({
						        activityId: item.activityId,
								name: item.title,
								id:item.id
						    })
						})
			            
			        } else {
			            // this.$message.error(msg || "操作失败");
			        }
			    })
			    .catch(() => {
			        // this.$message.error("操作失败");
			    })
			    .finally(() => {
			        // this.btnLoading = false;
			    });   
		},
        //表单提交
        submitForm(){
			if( this.formData.activityLicensed == 1  && this.sActivityList.length == 0 ){
				return this.$message.error("请先添加演出！");
			}else{
				var _licensedActivityIds = [];				
				this.sActivityList.map(item => {
					if( item.isNew == 1 ){
						 _licensedActivityIds.push(item.activityId);
					}				   
				})				
			}
			
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true
                    const _url = this.formData.userId ? '/subUser/update' : '/subUser/add';
                    this.$request({
                        url: _url,
                        data: {...this.formData,systime:new Date().getTime(),licensedActivityIds:_licensedActivityIds},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('onUpdate');
                                this.$message.success("操作成功");
                                this.close();
								
								this.getActivity()
                            } else {
                                this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });    
                    
                } else {
                    return false;
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
    .input-with-button{
        // ::v-deep .el-input-group__append{
        //     background: transparent;
        //     color: $--color-theme;
        // }
        .el-button{
            padding: 0 10px;
        }
    }
    .el-checkbox-group{
        font-size: 14px;
        .fl{
            margin-right: 10px;
            min-width: 100px;
        }
        .description{
            overflow: hidden;
            line-height: normal;
            padding-top: 10px;
            color: $--color-text-regular;
            max-width: 400px;
        }
    }
    
</style>