<template>
    <div>
        <div class="page-content-box">
			<div class="page-filter-box">
				<el-row :gutter="16">
					<el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
					    <el-input v-model="params.queryValue" class="input-with-select" size="small">
					        <el-select v-model="params.queryType" slot="prepend" placeholder="请选择">
					            <el-option label="手机" :value="1"></el-option>
					            <el-option label="头衔" :value="2"></el-option>
					        </el-select>
					    </el-input>
					</el-col>
					<el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
					    <el-button type="primary" @click="getDataList" size="small">搜索</el-button>
					    <el-button @click="reset" size="small">重置</el-button>
					</el-col>
				</el-row>
			</div>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >
                    <el-table-column prop="telephone" label="手机" min-width="100">
                        <template slot-scope="scope">
                            {{scope.row.telephone && scope.row.telephone.includes('tmp') ? '' : scope.row.telephone}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="idName" label="头衔"></el-table-column>
                    <el-table-column label="权限" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.roles" :key="index">
                                {{item.roleName}} {{scope.row.roles.length > (index + 1) ? '/' : ''}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastLoginTime" label="最近登录时间" min-width="160"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="210">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="edit(scope.row)"
                                >编辑</el-button
                            >
							<el-button
							    type="text"
							    size="small"
							    @click="setpassword(scope.row.userId)"
							    >设置专业版登录密码</el-button
							>
                            <el-button
                                type="text"
                                size="small"
                                @click="del(scope.row.userId)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div  class="page-pagination-box clearfix MT20">
                <div class="fl"><el-button type="primary" size="small"  @click="$refs.edit.show()">添加工作人员</el-button></div>
                <el-pagination
                    v-if="data"
                    class="fr"
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <edit ref="edit" @onUpdate="getDataList"></edit>
		<pwd ref="pwd" @onUpdate="getDataList"></pwd>
    </div>
</template>

<script>
import Edit from './components/edit';
import Pwd from './components/pwd';
const defaultParams = {
    pageNo: 1,
    queryType: 1,
    queryValue: "",
}
export default {
    components:{Edit,Pwd},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
				...defaultParams,
                pageNo: 1,
                pageSize: 15,
				queryType:parseInt(this.$route.query.queryType || 1),
				queryValue:this.$route.query.queryValue || '',
            },
            listLoading: true,
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/subUser/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
		//重置搜索
		reset() {
		    this.params = {
		        ...defaultParams,
		        pageSize: this.params.pageSize,
		    };
		    this.getDataList();
		},
        edit(data){
            this.$refs.edit.show(data);
        },
		setpassword(id){
		    this.$refs.pwd.show(id);
		},
        del(id){
            this.$confirm('是否删除当前工作人员？删除后不可恢复', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.$request({
                    url: '/subUser/del',
                    method: "POST",
                    params: {
                        userId:id
                    },
                })
                    .then((res) => {
                        const { state, msg } = res.data;
                        if (state == 1) {
                            this.getDataList();
                        } else {
                            this.$message.error(msg || "请求失败");
                        }
                    })
                    .catch(() => {
                        this.$message.error("请求错误");
                    })
                    .finally(() => {
                        // this.listLoading = false;
                    });
            }).catch(() => {});
        }
    }
}
</script>